import React, { Component } from "react";
import { Header, Segment, Button, Icon, Popup as ToolTip } from "semantic-ui-react";
import styled from "styled-components";
import * as turf from "@turf/turf";
import ReactMapboxGl, { ZoomControl, ScaleControl, Popup, Marker } from "react-mapbox-gl";
const { token, styles } = require("./config.json");
const Map = ReactMapboxGl({
  accessToken: token,
  preserveDrawingBuffer: true
});
const selectedStyles = ["outdoor", "sat"];
const switchStyles = Object.keys(styles).filter(k => selectedStyles.includes(k));
const Mark = styled.div`
  background-color: blue;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: 4px solid #eaa29b;
`;
const Missing = styled.div`
  background-color: maroon;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: 4px solid #ff0000;
`;
const Find = styled.div`
  background-color: green;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: 4px solid #10ff00;
`;
class TheMap extends Component {
  state = {
    styleKey: "sat",
    marker: []
  };
  componentDidMount = () => {};
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.ippLocation !== prevProps.ippLocation) {
      if (this.props.ippLocation.geometry) {
        this.mapBounds();
      }
    }
    if (this.props.findLocation !== prevProps.findLocation) {
      if (this.props.findLocation.geometry) {
        this.mapBounds();
      }
    }
  };
  nextStyle = e => {
    e.preventDefault();
    const { styleKey } = this.state;
    const currentIndex = switchStyles.indexOf(styleKey);
    const nextIndex = currentIndex === switchStyles.length - 1 ? 0 : currentIndex + 1;
    this.setState({ styleKey: switchStyles[nextIndex] });
  };
  onStyleLoad = map => {
    const { onStyleLoad } = this.props;
    return onStyleLoad && onStyleLoad(map);
  };
  mapBounds = () => {
    let data = [];
    if (this.map) {
      // if (this.props.missingFrom || this.props.findLocation) {
      if (this.props.ippLocation.geometry) {
        data.push(this.props.ippLocation.geometry.coordinates);
      }
      if (this.props.findLocation.geometry) {
        data.push(this.props.findLocation.geometry.coordinates);
      }

      let list = turf.multiPoint(data);
      let bbox = turf.bbox(list);
      this.map.state.map.fitBounds(bbox, { padding: 180 });
      // }
    }
  };
  _onClickMap = (map, evt) => {
    if (evt.lngLat) {
      let coords = [parseFloat(evt.lngLat.lng.toFixed(5)), parseFloat(evt.lngLat.lat.toFixed(5))];
      this.setState({
        marker: coords
      });
    }
  };
  addIPP = () => {
    this.props.ipp(this.state.marker);
    this.clearMarker();
  };
  addFind = () => {
    this.props.find(this.state.marker);
    this.clearMarker();
  };
  resetMap = () => {
    this.map.state.map.flyTo({
      bearing: 0,
      pitch: 0
    });
    this.mapBounds();
  };
  clearMarker = () => {
    this.setState({
      marker: []
    });
    if (this.props.onMapClick) {
      this.props.onMapClick(null);
    }
  };
  renderMarker() {
    return (
      <div>
        <Popup
          coordinates={this.state.marker}
          className={"locationPopup"}
          offset={{
            "bottom-left": [12, -20],
            bottom: [0, -20],
            "bottom-right": [-12, -20]
          }}
        >
          <Header as={"h2"}>Location Type</Header>
          <Header>
            Lng: {this.state.marker[0]}, Lat: {this.state.marker[1]}
          </Header>
          <Button onClick={() => this.addIPP()}>missing from (IPP)</Button>
          <Button onClick={() => this.addFind()}>find location</Button>
          <Button onClick={() => this.clearMarker()}>clear</Button>
        </Popup>
        <Marker coordinates={this.state.marker}>
          <Mark />
        </Marker>
      </div>
    );
  }
  renderMissing() {
    if (this.props.ippLocation.geometry) {
      return (
        <Marker coordinates={this.props.ippLocation.geometry && this.props.ippLocation.geometry.coordinates}>
          <Missing />
        </Marker>
      );
    }
    return null;
  }
  renderFind() {
    if (this.props.findLocation.geometry) {
      return (
        <Marker coordinates={this.props.findLocation.geometry && this.props.findLocation.geometry.coordinates}>
          <Find />
        </Marker>
      );
    }
    return null;
  }
  renderMapBar() {
    return (
      <div className="mapBar">
        <ToolTip
          trigger={
            <Button icon onClick={e => this.resetMap(e)} circular>
              <Icon color="orange" name="compass" />
            </Button>
          }
          content="reset bearing and pitch"
          basic
        />
        <ToolTip
          trigger={
            <Button icon onClick={e => this.nextStyle(e)} circular>
              <Icon name="world" />
            </Button>
          }
          content="Change Map Type"
          basic
        />
        {/* <ToolTip
          trigger={
            <Button icon onClick={e => this.toggleFull(e)} circular>
              <Icon name="expand" />
            </Button>
          }
          content="Toggle fullscreen"
          basic
        /> */}
        {/* <ToolTip
          trigger={
            <Button
              icon
              onClick={e => this.openPDF(e)}
              circular
              loading={this.state.printing}
            >
              <Icon name="print" color="blue" />
            </Button>
          }
          content="PDF map"
          basic
        /> */}
        {/* <ToolTip
          trigger={
            <Button
              icon
              onClick={() => {
                this.setState({
                  modalOpen: !this.state.modalOpen
                });
              }}
              circular
              loading={this.state.printing}
            >
              <Icon name="file pdf" color="blue" />
            </Button>
          }
          content="PDF map"
          basic
        /> */}
      </div>
    );
  }
  renderMap() {
    const { styleKey } = this.state;
    let center, zoom;
    if (!this.props.ippLocation.geometry) {
      center = [0.6937063, 51.2332213];
      zoom = [9];
    }
    if (this.state.marker.length) {
      center = this.state.marker;
      zoom = [12];
    }
    return (
      <Map
        ref={e => {
          this.map = e;
        }}
        onClick={this.props.click ? this._onClickMap : () => console.log("clicked")}
        center={center}
        className="basicMap"
        style={styles[styleKey]}
        onStyleLoad={this.onStyleLoad}
        zoom={zoom}
      >
        {" "}
        {this.state.marker.length && this.renderMarker()}
        {this.props.ippLocation && this.renderMissing()}
        {this.props.findLocation && this.renderFind()}
        <ZoomControl />
        <ScaleControl position={"bottom-left"} />
        {this.renderMapBar()}
      </Map>
    );
  }
  render() {
    return <Segment style={{ padding: 0, width: 100 + "%", height: 50 + "vh" }}>{this.renderMap()}</Segment>;
  }
}
export default TheMap;
