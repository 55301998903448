import React from "react";

import { Link } from "react-router-dom";
import { Menu, Icon } from "semantic-ui-react";
import { Authentication } from "@liquidcomputing/sarstuff__reactjs-singlesignon";

class FooterNav extends React.Component {
  state = {
    name: "--"
  };
  componentDidMount() {}
  componentDidUpdate(prevProps, nextProps) {}
  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };
  handleLogout = () => {
    Authentication.logout();
  };
  handleClear = () => {
    this.props.clear(() => this.props.history.push("/"));
  };

  render() {
    const { activeItem } = this.state;

    let system = "KSTAT Check";
    // if (this.props.system.settings.systemName) {
    //   system = this.props.system.settings.systemName;
    // }
    return (
      <Menu fixed={"bottom"} className={"footer-menu"}>
        <Menu.Item header as={"h3"}>
          <span className={"org-name"}>{system}</span>
        </Menu.Item>
        <Menu.Menu>
          <Menu.Item
            name={"settings"}
            as={Link}
            to="/settings"
            color="yellow"
            active={activeItem === "settings"}
            onClick={this.handleItemClick}
          >
            <Icon name="setting" color="yellow" />
            Settings
          </Menu.Item>
          <Menu.Item name={"logout"} color="yellow" onClick={this.handleLogout}>
            <Icon name="log out" color="orange" />
            Logout
          </Menu.Item>
        </Menu.Menu>
        <Menu.Menu position="right">
          {/* {this.props.chosenIncident.incident_id && ( */}
          <Menu.Item>
            {/* <Header as={"h3"} className={"subtle"}>
              Incident: {this.state.name}
            </Header> */}
          </Menu.Item>
          {/* )} */}
        </Menu.Menu>
      </Menu>
    );
  }
}

export default FooterNav;
