export default {
  app_storage_prefix: "kstat-check-prod_",
  api_server: "https://api.kstat.org/api",
  central_server: "https://central.sarsys.co.uk/api",
  bing_key: "AoMKVVkBRY3_8gv8UJmlIHXp8DCIGGzv9Yw_50Q4WIqC5ujgtVszQK-xLpB34hF2",
  auth: {
    clientId: "5c6a7d44afc77e39cd7676e4",
    loginServer: "https://central.sarstuff.co.uk",
    applicationRoot: "https://check.kstat.org"
  }
};