import React, { Component } from "react";
import { Header, Segment, Table, Grid } from "semantic-ui-react";
import api from "../actions/api";
export default class Home extends Component {
  state = {
    dash: {
      types: [],
      unPublishedtypes: [],
      county: []
    }
  };
  componentDidMount = () => {
    return api.search.dash().then(res => {
      this.setState({
        dash: res
      });
    });
  };
  renderPublished() {
    const types = this.state.dash.types.map(type => {
      return (
        <Table.Row key={type._id}>
          <Table.Cell>{type._id}</Table.Cell>
          <Table.Cell>{type.count}</Table.Cell>
        </Table.Row>
      );
    });
    return (
      <Segment>
        <Header textAlign="center">Published Misper Types</Header>
        <Table stackable celled>
          <Table.Body>{types}</Table.Body>
        </Table>
      </Segment>
    );
  }
  renderUnPublished() {
    const types = this.state.dash.unPublishedtypes.map(type => {
      return (
        <Table.Row key={type._id}>
          <Table.Cell>{type._id}</Table.Cell>
          <Table.Cell>{type.count}</Table.Cell>
        </Table.Row>
      );
    });
    return (
      <Segment>
        <Header textAlign="center">Un-Published Misper Types</Header>
        <Table stackable celled>
          <Table.Body>{types}</Table.Body>
        </Table>
      </Segment>
    );
  }
  renderTeams() {
    const types = this.state.dash.county.map(type => {
      return (
        <Table.Row key={type._id}>
          <Table.Cell>{type._id}</Table.Cell>
          <Table.Cell>{type.count}</Table.Cell>
        </Table.Row>
      );
    });
    return (
      <Segment>
        <Header textAlign="center">Searches by County</Header>
        <Table stackable celled>
          <Table.Body>{types}</Table.Body>
        </Table>
      </Segment>
    );
  }
  render() {
    return (
      <Segment>
        <Grid>
          <Grid.Row columns={"equal"}>
            <Grid.Column>{this.renderPublished()}</Grid.Column>
            <Grid.Column>{this.renderUnPublished()}</Grid.Column>
            <Grid.Column>{this.renderTeams()}</Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
