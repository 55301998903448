import React, { Component } from "react";
import { getSystemSettings, updateSetting, getMisperTypes, getLocationTypes, getMissingFrom, getAreaType } from "../../actions/settingsActions";
import { connect } from "react-redux";

import { Grid, Segment, Form, Message, Icon, Popup, Checkbox, Header, Button } from "semantic-ui-react";
// import { getSystemSettings, updateSetting } from "../../actions/settingsActions";
function mapStateToProps(state) {
  return {
    system: state.system
  };
}
class Dash extends Component {
  state = {
    disabled: true,
    data: {
      teamName: "",
      systemName: ""
    }
  };
  updateFormTimeout = 0;
  componentDidMount() {
    this.getData();
    this.props.getSystemSettings();
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.system !== prevProps.system) {
      this.setState({
        data: this.props.system.settings
      });
    }
  };
  getData = () => {
    this.getMisperTypes();
    this.getLocationTypes();
    this.getMissingFrom();
    this.getAreaType();
  };
  syncMisperTypes = () => {
    const data = {
      misperTypes: this.state.misperTypes
    };
    this.props.updateSetting(data, () => {});
  };
  syncLocationTypes = () => {
    const data = {
      findLocationTypes: this.state.findLocationTypes
    };
    this.props.updateSetting(data, () => {});
  };
  syncMissingFrom = () => {
    const data = {
      missingFrom: this.state.missingFrom
    };
    this.props.updateSetting(data, () => {});
  };
  syncAreaType = () => {
    const data = {
      areaType: this.state.areaType
    };
    this.props.updateSetting(data, () => {});
  };
  getMisperTypes = () => {
    this.props.getMisperTypes().then(res => {
      let data = [];
      res.map(type => {
        return data.push(type.Title);
      });
      this.setState({
        misperTypes: data
      });
    });
    return null;
  };
  getLocationTypes = () => {
    this.props.getLocationTypes().then(res => {
      let data = [];

      res.map(type => {
        if (res.Title) {
          return data.push(type.Title);
        }
      });
      this.setState({
        findLocationTypes: data
      });
    });
  };
  getMissingFrom = () => {
    this.props.getMissingFrom().then(res => {
      let data = [];
      res.map(type => {
        return data.push(type.Title);
      });
      this.setState({
        missingFrom: data
      });
    });
  };
  getAreaType = () => {
    this.props.getAreaType().then(res => {
      let data = [];
      res.map(type => {
        return data.push(type.Title);
      });
      this.setState({
        areaType: data
      });
    });
  };
  toggleDisabled = () => {
    this.setState({
      disabled: !this.state.disabled
    });
  };
  handleCheckboxChange = (props, e) => {
    this.setState(
      {
        updating: true,
        data: { ...this.state.data, [e.name]: e.checked }
      },
      () => {
        if (this.updateFormTimeout) {
          clearTimeout(this.updateFormTimeout);
        }
        this.updateFormTimeout = setTimeout(() => {
          return this.props
            .updateSetting(this.state.data)
            .then(res => {
              this.setState({
                updating: false
              });
              //TODO add a fancy screen notification that the title has successfully updated
            })
            .catch(err => {
              console.error("Unable to save", err);
            });
        }, 500);
      }
    );
  };
  onChange = e => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  };
  updateModel = e => {
    // this.setState({
    //   updating: true,
    //   data: { ...this.state.data, [e.target.name]: e.target.value }
    // });

    if (this.updateFormTimeout) {
      clearTimeout(this.updateFormTimeout);
    }
    this.updateFormTimeout = setTimeout(() => {
      return this.props
        .updateSetting(this.state.data)
        .then(res => {
          this.setState({
            updating: false
          });
          //TODO add a fancy screen notification that the title has successfully updated
        })
        .catch(err => {
          console.error("Unable to save", err);
        });
    }, 500);
  };
  renderSwitches() {
    return (
      <Segment basic>
        <Header>Controls</Header>
        <Form>
          <Form.Group grouped>
            <Form.Field>
              <Checkbox toggle id="appAccess" name="appAccess" checked={this.state.data.appAccess} label="Allow App Access" onChange={this.handleCheckboxChange} />
            </Form.Field>
            <Form.Field>
              <Checkbox toggle id="haveTeamSite" name="haveTeamSite" checked={this.state.data.haveTeamSite} label="Has Teamsite" onChange={this.handleCheckboxChange} />
            </Form.Field>
          </Form.Group>
        </Form>
      </Segment>
    );
  }
  render() {
    return (
      <Segment basic>
        <Grid>
          <Grid.Row>
            <Grid.Column width={6}>
              <Segment>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width="10">
                      <Form>
                        <Form.Field>
                          <label htmlFor="teamName">Team Name</label>
                          <input
                            name="teamName"
                            id={"teamName"}
                            type="text"
                            value={this.state.data && this.state.data.teamName}
                            disabled={this.state.disabled}
                            onChange={this.onChange}
                            onBlur={this.updateModel}
                          />
                        </Form.Field>
                        <Form.Field>
                          <label htmlFor="systemName">System Name</label>
                          <input
                            name="systemName"
                            id={"systemName"}
                            type="text"
                            value={this.state.data && this.state.data.systemName}
                            disabled={this.state.disabled}
                            onChange={this.updateModel}
                          />
                        </Form.Field>
                      </Form>
                    </Grid.Column>
                    <Grid.Column width="6" verticalAlign="middle">
                      {this.state.disabled ? (
                        <Popup trigger={<Icon name="lock" size={"huge"} color="red" onClick={this.toggleDisabled} />} content="unlock note" />
                      ) : (
                        <Icon name="unlock" size={"huge"} color="green" onClick={this.toggleDisabled} />
                      )}
                    </Grid.Column>
                    <Message icon hidden={!this.state.updating} positive size="mini" floating>
                      {/* <Icon name="circle notched" loading /> */}
                      <Message.Content>
                        <Message.Header>Just one second</Message.Header>
                        Updating form.
                      </Message.Content>
                    </Message>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Grid.Column>
            <Grid.Column width={6}>
              <Segment>{this.renderSwitches()}</Segment>
            </Grid.Column>
            <Grid.Column width={4}>
              <Segment>
                <Header>Sync Lists</Header>
                <Button.Group vertical labeled icon>
                  <Button color="red" icon="sync" onClick={() => this.syncMisperTypes()} content="Sync Misper Types" />
                  <Button color="red" icon="sync" onClick={() => this.syncLocationTypes()} content="Sync Location Types" />
                  <Button color="red" icon="sync" onClick={() => this.syncMissingFrom()} content="Sync Missing From Types" />
                  <Button color="red" icon="sync" onClick={() => this.syncAreaType()} content="Sync Area Types" />
                </Button.Group>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

export default connect(
  mapStateToProps,
  { getSystemSettings, updateSetting, getMisperTypes, getLocationTypes, getMissingFrom, getAreaType }
)(Dash);
