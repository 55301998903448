import React from "react";
import moment from "moment";
import { withRouter } from "react-router";
import { Segment, Button, Table, Icon, Popup } from "semantic-ui-react";
class SearchList extends React.Component {
  state = {};
  renderDate = date => {
    if (date) {
      return moment(date).format("DD/MM/YY HH:mm");
    } else {
      return "--";
    }
  };
  renderIncidents() {
    if (this.props.data.length === 0) {
      return (
        <Table.Row key={1}>
          <Table.Cell colSpan={6}>No Incidents</Table.Cell>
        </Table.Row>
      );
    }
    const incidents = this.props.data.map(incident => {
      return (
        <Table.Row key={incident._id}>
          <Table.Cell>{incident.misperType}</Table.Cell>
          <Table.Cell>{this.renderDate(incident.createdAt)}</Table.Cell>
          <Table.Cell>{this.renderDate(incident.dateMissing)}</Table.Cell>
          <Table.Cell>{this.renderDate(incident.dateFound)}</Table.Cell>
          <Table.Cell>{incident.published ? "yes" : "no"}</Table.Cell>
          <Table.Cell>{incident.checked ? "yes" : "no"}</Table.Cell>
          <Table.Cell>{incident.ignore ? "yes" : "no"}</Table.Cell>
          <Table.Cell width={2}>{incident.adminNote}</Table.Cell>
          <Table.Cell>{incident.team}</Table.Cell>
          <Table.Cell>{incident.county}</Table.Cell>
          <Table.Cell>
            {incident.source}({incident.sourceID})
          </Table.Cell>
          <Table.Cell>
            <Popup
              trigger={
                <Button basic icon color={"orange"} onClick={() => this.props.history.push(`${incident._id}`)}>
                  <Icon name="check" />
                </Button>
              }
              content={"Make active"}
            />
          </Table.Cell>
        </Table.Row>
      );
    });
    return incidents;
  }
  render() {
    return (
      <Segment basic>
        <Table stackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Misper Type</Table.HeaderCell>
              <Table.HeaderCell>Date Added</Table.HeaderCell>
              <Table.HeaderCell>Date Missing</Table.HeaderCell>
              <Table.HeaderCell>Date Found</Table.HeaderCell>
              <Table.HeaderCell>Published</Table.HeaderCell>
              <Table.HeaderCell>Checked</Table.HeaderCell>
              <Table.HeaderCell>Ignore</Table.HeaderCell>
              <Table.HeaderCell>Admin Note</Table.HeaderCell>
              <Table.HeaderCell>Team</Table.HeaderCell>
              <Table.HeaderCell>County</Table.HeaderCell>
              <Table.HeaderCell>Source</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>{this.renderIncidents()}</Table.Body>
        </Table>
      </Segment>
    );
  }
}
export default withRouter(SearchList);
