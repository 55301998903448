import React, { Component } from "react";
import api from "../../actions/api";
import { Link, withRouter } from "react-router-dom";
import { Segment, Button, Divider, List, TextArea, Form, Header, Grid, Input, Select } from "semantic-ui-react";
import Moment from "react-moment";
import * as m from "moment";
import * as turf from "@turf/turf";
import Map from "../common/basicMap/map";

const areaTypes = [
  { key: 1, text: "Rural", value: "Rural" },
  { key: 2, text: "Urban", value: "Urban" },
  { key: 3, text: "Mixed", value: "Mixed" },
  { key: 4, text: "Water", value: "Water" }
];
const condition = [{ key: 1, text: "Uninjured", value: "Uninjured" }, { key: 2, text: "Injured", value: "Injured" }, { key: 3, text: "Fatality", value: "Fatality" }];

class ShowSearch extends Component {
  state = {
    edit: false,
    missingFromType: [],
    findLocationType: [],
    search: {
      ippLocation: {},
      findLocation: {},
      possDuplicates: [],
      adminNote: ""
    },
    warning: {
      distance: false
    }
  };
  componentDidMount = () => {
    this.getData();
    this.getDefaults();
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.location !== this.props.location) {
      this.getData();
    }
  };

  getData = () => {
    return api.search.show(this.props.match.params.id).then(res => {
      this.setState(
        {
          search: {
            ...this.state.search,
            ...res
          }
        },
        () => {
          this.fieldCheck();
        }
      );
    });
  };
  getDefaults = () => {
    api.stats.getMissingFrom().then(res => {
      const data = res;
      let dataArray = [];
      for (var key in data) {
        dataArray.push({
          key: key,
          text: data[key],
          value: data[key]
        });
      }
      this.setState({
        missingFromType: dataArray
      });
    });
    api.stats.getLocationTypes().then(locs => {
      const data = locs;
      let locArray = [];
      for (var key in data) {
        locArray.push({
          key: key,
          text: data[key],
          value: data[key]
        });
      }
      this.setState({ findLocationType: locArray });
    });
  };

  onChange = e => {
    this.setState({
      search: { ...this.state.search, [e.target.name]: e.target.value }
    });
  };
  onCheckChange = (props, e) => {
    this.setState({
      search: { ...this.state.search, [e.name]: e.checked }
    });
  };
  onPubChange = (e, { value }) => {
    if (value === 1) {
      this.setState({
        search: {
          ...this.state.search,
          published: true,
          ignore: false,
          checked: true
        }
      });
    } else if (value === 0) {
      this.setState({
        search: {
          ...this.state.search,
          published: false,
          ignore: true,
          checked: true
        }
      });
    }
  };
  handleDropdownChange = (props, e) => {
    this.setState({
      search: { ...this.state.search, [e.name]: e.value }
    });
  };
  renderSelect = props => {
    return (
      <Form.Field>
        {/* <label>{props.label}</label> */}
        <Select placeholder={"pick one"} name={props.name} options={props.data} value={props.value} onChange={this.handleDropdownChange} />
      </Form.Field>
    );
  };
  fieldCheck = () => {
    const warning = {};
    const { search } = this.state;
    if (search.distance === 0) warning.distance = true;
    if (search.distance > 40) warning.distance = true;
    if (search.dateMissing && search.dateFound) {
      let from = m(search.dateMissing);
      let to = m(search.dateFound);
      let duration = to.diff(from, "days");
      if (duration > 5 && search.misperCondition !== "Fatality") {
        warning.dates = true;
        warning.condition = true;
        warning.distance = true;
      }
    }
    if (search.areaType === "Watercourse") warning.areaType = true;
    switch (search.misperCondition) {
      case "Fatality":
        warning.condition = false;
        break;
      case "Injured":
        warning.condition = false;
        break;
      case "Uninjured":
        warning.condition = false;
        break;
      default:
        warning.condition = true;
    }
    this.setState({
      warning
    });
  };
  resetForm = e => {
    e.preventDefault();
    this.setState({
      data: {
        adminNote: "",
        published: false,
        checked: false,
        ignore: false
      },
      edit: false
    });
  };
  onSubmit = () => {
    api.search.check(this.props.match.params.id, this.state.search).then(() => {
      this.props.history.push(`/searches/unchecked`);
      // this.getData();
    });
  };

  renderControls() {
    const { search } = this.state;
    return (
      <Segment basic>
        {!this.state.edit ? (
          <Segment>
            <Header as={"h2"} textAlign="center">
              This search was marked as{" "}
              {search.published ? (
                <React.Fragment>
                  <span style={{ color: "green" }}> published </span>
                  <br /> on <Moment format="DD/MM/YYYY HH:mm">{search.dateChecked}</Moment> by {search.checkedBy}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <span style={{ color: "red" }}> ignore </span>
                  <br /> on <Moment format="DD/MM/YYYY HH:mm">{search.dateChecked}</Moment> by {search.checkedBy}
                </React.Fragment>
              )}
            </Header>
            <p>Admin Note: {search.adminNote}</p>
            <p>Inputers Note: {search.inputNote}</p>
            <Button color="orange" fluid basic onClick={() => this.setState({ edit: true })}>
              Change
            </Button>
          </Segment>
        ) : (
          <Form onSubmit={this.onSubmit}>
            <Header>Search Controls</Header>
            <Form.Group inline widths="equal" className="checkboxGroup">
              <Form.Checkbox radio name="published" value={1} checked={search.published == true} label="Publish Search" onChange={this.onPubChange} />
              <Form.Checkbox radio name="published" value={0} checked={search.ignore == true} label="Ignore Search" onChange={this.onPubChange} />
              <Form.Checkbox name="checked" checked={search.checked} label="Search Checked" onChange={this.onCheckChange} />
            </Form.Group>
            <Divider />
            <Form.Field>
              <label htmlFor="adminNote">Admin Note</label>
              <TextArea
                name="adminNote"
                id="adminNote"
                placeholder="why is this search being ignored?"
                style={{ minHeight: 100 }}
                value={search.adminNote ? search.adminNote : ""}
                onChange={this.onChange}
              />
            </Form.Field>
            <Form.Group widths={"equal"} className={"form-actions"}>
              <Form.Field>
                <Button negative floated={"left"} onClick={this.resetForm}>
                  Cancel
                </Button>
              </Form.Field>
              <Form.Field>
                <Button positive floated={"right"}>
                  update search
                </Button>
              </Form.Field>
            </Form.Group>
          </Form>
        )}
        {this.renderPossDuplicates()}
      </Segment>
    );
  }
  renderDetails() {
    const { search, warning } = this.state;
    let from, to, distance;
    if (search.ippLocation && search.ippLocation.geometry) {
      from = turf.point(search.ippLocation.geometry.coordinates);
    }
    if (search.findLocation && search.findLocation.geometry) {
      to = turf.point(search.findLocation.geometry.coordinates);
    }
    if (from && to) {
      let options = { units: "kilometers" };
      distance = turf.distance(from, to, options).toFixed(2);
    }

    return (
      <Grid stackable celled className="dataGrid">
        <Grid.Row columns={"equal"}>
          <Grid.Column>
            <Header>Date Added</Header>
            <p>{search.createdDate ? <Moment fromNow>{search.createdDate}</Moment> : <Moment fromNow>{search.createdAt}</Moment>}</p>
          </Grid.Column>
          <Grid.Column>
            <Header>Misper Type</Header> <p>{search.misperType}</p>
          </Grid.Column>
          <Grid.Column width={1}>
            <Header>Age</Header> <p>{search.age}</p>
          </Grid.Column>
          <Grid.Column width={1}>
            <Header>Gender</Header>
            <p>{search.gender}</p>
          </Grid.Column>
          <Grid.Column>
            <Header>Date Missing</Header>{" "}
            <p>
              <Moment format="DD/MM/YYYY HH:mm">{search.dateMissing}</Moment>
            </p>
          </Grid.Column>
          <Grid.Column>
            <Header>Date Found</Header>{" "}
            <p>
              <Moment format="DD/MM/YYYY HH:mm">{search.dateFound}</Moment>
            </p>
          </Grid.Column>
          <Grid.Column width={2} className={warning.dates ? "warning" : ""}>
            <Header>Duration</Header>
            <p>
              <Moment duration={search.dateMissing} date={search.dateFound} />
            </p>
          </Grid.Column>
          <Grid.Column className={warning.condition ? "warning" : ""}>
            <Header>Misper Condition</Header>
            {this.state.edit ? (
              this.renderSelect({ label: "Misper Condition", data: condition, value: search.misperCondition, name: "misperCondition" })
            ) : (
              <p>{search.misperCondition}</p>
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={"equal"}>
          <Grid.Column>
            <Header>IPP</Header>
            <p>{search.ippLocation && search.ippLocation.properties && search.ippLocation.properties.grid}</p>
            {search.ippLocation && search.ippLocation.geometry && (
              <p>
                {search.ippLocation.geometry.coordinates[0]}, {search.ippLocation.geometry.coordinates[1]}
              </p>
            )}
          </Grid.Column>
          <Grid.Column>
            <Header>Find Location</Header>
            <p>{search.findLocation && search.findLocation.properties && search.findLocation.properties.grid}</p>
            {search.findLocation && search.findLocation.geometry && (
              <p>
                {search.findLocation.geometry.coordinates[0]}, {search.findLocation.geometry.coordinates[1]}
              </p>
            )}
          </Grid.Column>
          <Grid.Column width={2} className={warning.distance ? "warning" : ""}>
            <Header>Distance</Header>
            <p>{distance} km</p>
            {search.distance && <p>{search.distance} km</p>}
          </Grid.Column>

          <Grid.Column width={2}>
            <Header>Track Offset</Header>
            <p>{search.trackOffset} meters</p>
          </Grid.Column>
          <Grid.Column>
            <Header>Missing from Type</Header>{" "}
            {this.state.edit ? (
              this.renderSelect({ label: "Missing From Types", data: this.state.missingFromType, value: search.missingFromType, name: "missingFromType" })
            ) : (
              <p>{search.missingFromType}</p>
            )}
          </Grid.Column>
          <Grid.Column>
            <Header>Find Location Type</Header>
            {this.state.edit ? (
              this.renderSelect({ label: "Find Location", data: this.state.findLocationType, value: search.findLocationType, name: "findLocationType" })
            ) : (
              <p>{search.findLocationType}</p>
            )}
          </Grid.Column>
          <Grid.Column width={2} className={warning.areaType ? "warning" : ""}>
            <Header>Area Type</Header>
            {this.state.edit ? this.renderSelect({ label: "Area Type", data: areaTypes, value: search.areaType, name: "areaType" }) : <p>{search.areaType}</p>}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={"equal"}>
            <Grid.Column width={2}>
            <Header>Country</Header>
            <p>{search.country}</p>
          </Grid.Column>
          <Grid.Column width={2}>
            <Header>County</Header>
            <p>{search.county}</p>
          </Grid.Column>
          <Grid.Column width={2}>
            <Header>Team</Header>
            <p>{search.team}</p>
          </Grid.Column>
          <Grid.Column width={2}>
            <Header>Source</Header>
            <p>{search.source}</p>
          </Grid.Column>
          <Grid.Column>
            <Header>Outcome</Header>
            <p>{search.outcome}</p>
          </Grid.Column>
          <Grid.Column>
            <Header>Source Ref:</Header>
            <p>{search.extRef}</p>
          </Grid.Column>
          <Grid.Column>
            <Header>Notes</Header>
            <p>{search.notes}</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={"equal"}>
          <Grid.Column className="no-pad">
            {search.ippLocation && search.findLocation ? <Map ippLocation={search.ippLocation} findLocation={search.findLocation} /> : <Header>No locations</Header>}
          </Grid.Column>
          <Grid.Column>{this.renderControls()}</Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
  goto = () => {
    this.props.history.push(`/searches/${this.state.search._id}`);
    // return <Redirect to={`/searches/${this.state.search._id}/bob`} />;
  };
  renderPossDuplicates() {
    const list = this.state.search.possDuplicates.map(search => {
      return (
        <List.Item key={search._id}>
          <Link to={`/searches/${search._id}`}>
            <Moment format="DD/MM/YYYY HH:mm">{search.dateMissing}</Moment> - {search.published ? "Published" : "Not Published"} - {!search.checked && "Not Checked!"}{" "}
            {search.county} - {search.team} - {search.misperType} - {search.age}
          </Link>
        </List.Item>
      );
    });
    return (
      <Segment>
        <Header>Possible Duplicates</Header>
        <List divided verticalAlign="middle">
          {list}
        </List>
      </Segment>
    );
  }
  render() {
    console.log("s", this.state);

    return <Segment>{this.renderDetails()}</Segment>;
  }
}
export default withRouter(ShowSearch);
