import React from "react";
import moment from "moment";
import { Segment } from "semantic-ui-react";
import api from "../../actions/api";
import SearchList from "../common/lists/searchList";
export default class AllSearchesList extends React.Component {
  state = {
    searches: []
  };
  renderDate = date => {
    if (date) {
      return moment(date).format("DD/MM/YY HH:mm");
    } else {
      return "--";
    }
  };
  componentDidMount = () => {
    return api.search.all().then(res => {
      this.setState({
        searches: res
      });
    });
  };

  render() {
    return (
      <Segment basic>
        <SearchList data={this.state.searches} />
      </Segment>
    );
  }
}
