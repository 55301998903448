import React, { createRef, Component } from "react";
import { Icon, Header, Segment, Grid, Button, Input, Divider, Select, Form } from "semantic-ui-react";
import Geosuggest from "react-geosuggest";
import moment from "moment";
import * as turf from "@turf/turf";
import TheMap from "../../common/basicMap/map";
import MaskInput from "react-maskinput";
// import { Authentication } from "@liquidcomputing/sarstuff__reactjs-singlesignon";

const gender = [{ key: 1, text: "Male", value: "Male" }, { key: 2, text: "Female", value: "Female" }];

const condition = [{ key: 1, text: "Uninjured", value: "Uninjured" }, { key: 2, text: "Injured", value: "Injured" }, { key: 3, text: "Fatality", value: "Fatality" }];

export default class AddStatFrom extends Component {
  state = {
    types: [],
    locTypes: [],
    areaTypes: [],
    outcomes: [],
    theAreaTypes: [],
    missingTypes: [],
    misperTypes: [],
    locationTypes: [],
    missingFromTypes: [],
    step: 1,
    data: {
      misperType: "",
      gender: "",
      age: 0,
      ippLocation: {},
      findLocation: {},
      dateFound: "",
      dateMissing: "",
      areaType: "",
      trackOffset: 0,
      missingFromType: "",
      findLocationType: "",
      misperCondition: "",
      county: "Kent",
      team: "Kent Police",
      source: "Direct Input",
      outcome: ""
    },
    settings: {
      misperTypes: []
    },
    maskString: "DD/MM/YYYY HH:mm",
    mask: "00/00/0000 00:00"
  };
  mapRef = createRef();
  componentDidMount = () => {};
  componentDidUpdate(prevState, prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.state.data !== prevState.data) {
      this.props.data(this.state.data);
    }
    if (this.props.settings) {
      if (this.props.settings !== prevProps.settings) {
        this.setState({
          settings: this.props.settings
          // types: this.props.settings.misperTypes,
          // locTypes: this.props.settings.findLocationTypes,
          // missingTypes: this.props.settings.missingFrom,
          // areaTypes: this.props.settings.areaType
        });
        this.setDropDowns();
      }
    }
  }
  setDropDowns = () => {
    this.getMissingFromTypes();
    this.getMisperTypes();
    this.getAreaTypes();
    this.getLocationTypes();
    this.getOutcomes();
  };
  handleClick = () => {
    const map = this.mapRef.current;
    if (map != null) {
      map.leafletElement.locate();
    }
  };
  handleLocationFound = (e: Object) => {
    this.setState({
      hasLocation: true,
      latlng: e.latlng
    });
  };
  onSuggestSelect = suggest => {
    if (suggest) {
      this.setState({
        data: {
          ...this.state.data,
          ippLocation: {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [parseFloat(suggest.location.lng).toFixed(4), parseFloat(suggest.location.lat).toFixed(4)]
            },
            properties: {
              address: suggest.gmaps.formatted_address
            }
          }
        },
        step: 5
      });
    }
  };
  onSuggestFind = suggest => {
    if (suggest) {
      this.setState({
        data: {
          ...this.state.data,
          findLocation: {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [parseFloat(suggest.location.lng).toFixed(4), parseFloat(suggest.location.lat).toFixed(4)]
            },
            properties: {
              address: suggest.gmaps.formatted_address
            }
          }
        }
      });
    }
  };
  handleDropdownChange = (props, e) => {
    this.setState({
      data: { ...this.state.data, [e.name]: e.value }
    });
  };
  clearForm = e => {
    this.forceUpdate();
    // this.props.reset();
    this.setState({
      data: {
        misperType: "",
        gender: "",
        age: 0,
        ippLocation: {},
        findLocation: {},
        dateFound: "",
        dateMissing: "",
        areaType: "",
        trackOffset: 0,
        missingFromType: "",
        findLocationType: "",
        misperCondition: ""
      }
    });
  };
  getMisperTypes = () => {
    if (this.props.settings.misperTypes) {
      const data = this.props.settings.misperTypes;
      let dataArray = [];
      for (var key in data) {
        dataArray.push({
          key: key,
          text: data[key],
          value: data[key]
        });
      }
      this.setState({
        misperTypes: dataArray.sort((a, b) => {
          if (a.text < b.text) {
            return -1;
          } else if (a.text > b.text) {
            return 1;
          }
          return 0;
        })
      });
    }
  };
  getOutcomes = () => {
    if (this.props.settings.outcome) {
      const data = this.props.settings.outcome;
      let dataArray = [];
      for (var key in data) {
        dataArray.push({
          key: key,
          text: data[key],
          value: data[key]
        });
      }
      this.setState({
        outcomes: dataArray.sort((a, b) => {
          if (a.text < b.text) {
            return -1;
          } else if (a.text > b.text) {
            return 1;
          }
          return 0;
        })
      });
    }
  };
  getLocationTypes = () => {
    if (this.props.settings.findLocationTypes) {
      const data = this.props.settings.findLocationTypes;
      let dataArray = [];
      for (var key in data) {
        dataArray.push({
          key: key,
          text: data[key],
          value: data[key]
        });
      }
      this.setState({
        locationTypes: dataArray.sort((a, b) => {
          if (a.text < b.text) {
            return -1;
          } else if (a.text > b.text) {
            return 1;
          }
          return 0;
        })
      });
    }
  };
  getMissingFromTypes = () => {
    if (this.props.settings.missingFrom) {
      const data = this.props.settings.missingFrom;
      let dataArray = [];
      for (var key in data) {
        dataArray.push({
          key: key,
          text: data[key],
          value: data[key]
        });
      }
      this.setState({
        missingFromTypes: dataArray.sort((a, b) => {
          if (a.text < b.text) {
            return -1;
          } else if (a.text > b.text) {
            return 1;
          }
          return 0;
        })
      });
    }
  };
  getAreaTypes = () => {
    if (this.props.settings.areaType) {
      const data = this.props.settings.areaType;
      let dataArray = [];
      for (var key in data) {
        dataArray.push({
          key: key,
          text: data[key],
          value: data[key]
        });
      }
      this.setState({
        theAreaTypes: dataArray.sort((a, b) => {
          if (a.text < b.text) {
            return -1;
          } else if (a.text > b.text) {
            return 1;
          }
          return 0;
        })
      });
    }
  };
  onDateChange = e => {
    this.setState({
      maskString: "DD/MM/YYYY HH:mm",
      mask: "00/00/0000 00:00"
    });
    let date = moment(e.target.value, "DD/MM/YYYY HH:mm");
    this.setState({
      data: { ...this.state.data, [e.target.name]: date }
    });
  };
  onChange = e =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  addipp = data => {
    this.setState({
      data: {
        ...this.state.data,
        ippLocation: {
          ...this.state.data.ippLocation,
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: data
          },
          properties: {
            // ...this.state.data.missingFrom.properties
          }
        }
      }
    });
  };
  addFind = data => {
    this.setState({
      data: {
        ...this.state.data,
        findLocation: {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: data
          },
          properties: {}
        }
      }
    });
  };
  renderSelect = props => {
    return (
      <Form.Field>
        <label>{props.label}</label>
        <Select placeholder={"pick one"} name={props.name} options={props.data} value={props.value} onChange={this.handleDropdownChange} />
      </Form.Field>
    );
  };

  renderMissingFrom = () => {
    return (
      <React.Fragment>
        {!this.state.data.ippLocation.geometry && (
          <React.Fragment>
            <Header>
              The IPP
              <span style={{ color: "white" }}>
                <small> (PLS/LKP)</small>
              </span>
            </Header>
            <Geosuggest
              placeholder="4 the street, Ashford Kent"
              inputClassName="geosuggest__input"
              country="gb"
              location={new window.google.maps.LatLng(51.2332213, 0.6937063)}
              radius="80"
              onSuggestSelect={this.onSuggestSelect}
              suggestsClassName="geosuggest__suggests"
              suggestsHiddenClassName="geosuggest__suggests--hidden"
              suggestItemClassName="geosuggest__item"
            />
          </React.Fragment>
        )}
        {this.state.data.ippLocation.geometry && (
          <React.Fragment>
            <Header>
              Missing From Coordinates{" "}
              <Button
                size="tiny"
                icon
                onClick={() =>
                  this.setState({
                    data: {
                      ...this.state.data,
                      ippLocation: {}
                    }
                  })
                }
              >
                <Icon name="delete" color="red" />
              </Button>
            </Header>
            <pre>{JSON.stringify(this.state.data.ippLocation.geometry, null, 2)}</pre>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };
  renderFindLocation = () => {
    return (
      <React.Fragment>
        {!this.state.data.findLocation.geometry && (
          <React.Fragment>
            <Header>Find Location</Header>
            <Geosuggest
              placeholder="4 the street, Ashford Kent"
              inputClassName="geosuggest__input"
              country="gb"
              location={new window.google.maps.LatLng(51.2332213, 0.6937063)}
              radius="80"
              onSuggestSelect={this.onSuggestFind}
              suggestsClassName="geosuggest__suggests"
              suggestsHiddenClassName="geosuggest__suggests--hidden"
              suggestItemClassName="geosuggest__item"
            />
          </React.Fragment>
        )}
        {this.state.data.findLocation.geometry && (
          <React.Fragment>
            <Header>
              Find Location Coordinates{" "}
              <Button
                size="tiny"
                icon
                onClick={() =>
                  this.setState({
                    data: {
                      ...this.state.data,
                      findLocation: {}
                    }
                  })
                }
              >
                <Icon name="delete" color="red" />
              </Button>
            </Header>
            <pre>{JSON.stringify(this.state.data.findLocation.geometry, null, 2)}</pre>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };
  render() {
    let from, to, distance;
    if (this.state.data.ippLocation.geometry) {
      from = turf.point(this.state.data.ippLocation.geometry.coordinates);
    }
    if (this.state.data.findLocation.geometry) {
      to = turf.point(this.state.data.findLocation.geometry.coordinates);
    }
    if (from && to) {
      let options = { units: "kilometers" };
      distance = turf.distance(from, to, options);
    }
    // console.log(Authentication.getUserData());
    // console.log(this.props);

    return (
      <Segment basic>
        <Grid>
          <Grid.Row columns="2">
            <Grid.Column>
              {/* {step === 1 && this.renderTypes()}
              {step === 2 && this.renderGender()} */}
              <Form>
                <Grid>
                  <Grid.Row columns={"equal"}>
                    <Grid.Column>{this.renderSelect({ label: "Misper Type", data: this.state.misperTypes, value: this.state.data.misperType, name: "misperType" })}</Grid.Column>
                    <Grid.Column>{this.renderSelect({ label: "Gender", data: gender, value: this.state.data.gender, name: "gender" })}</Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={"equal"}>
                    <Grid.Column>
                      <Form.Field>
                        <label>Age</label>
                        <Input name="age" type="number" placeholder="78..." action onChange={this.onChange} />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column>{this.renderSelect({ label: "Misper Condition", data: condition, value: this.state.data.misperCondition, name: "misperCondition" })}</Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={"equal"}>
                    <Grid.Column>
                      <Form.Field>
                        <label>
                          Date Missing <small style={{ color: "white" }}>(DD/MM/YYYY HH:mm)</small>
                        </label>
                        {/* <Input name="dateMissing" type="text" placeholder="YYY MM DD HH:mm" action onChange={this.onChange} /> */}
                        <MaskInput name="dateMissing" onChange={this.onDateChange} maskString={this.state.maskString} mask={this.state.mask} />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Field>
                        <label>
                          Date Found <small style={{ color: "white" }}>(DD/MM/YYYY HH:mm)</small>
                        </label>
                        <MaskInput name="dateFound" onChange={this.onDateChange} maskString={this.state.maskString} mask={this.state.mask} />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <Form.Field>
                        {this.renderSelect({ label: "Outcome Type", data: this.state.outcomes, value: this.state.data.outcome, name: "outcome" })}
                        {/* <Input name="outcome" type="text" placeholder="found by member of the public" onChange={this.onChange} /> */}
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width="16">
                      <Divider />
                      <Header>Location Type Info</Header>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={"equal"}>
                    <Grid.Column>
                      {this.renderSelect({ label: "Missing From Types", data: this.state.missingFromTypes, value: this.state.data.missingFromType, name: "missingFromType" })}
                    </Grid.Column>

                    <Grid.Column>
                      {this.renderSelect({ label: "Find Location Type", data: this.state.locationTypes, value: this.state.data.findLocationType, name: "findLocationType" })}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={"equal"}>
                    <Grid.Column>{this.renderSelect({ label: "Area Type", data: this.state.theAreaTypes, value: this.state.data.areaType, name: "areaType" })}</Grid.Column>
                    <Grid.Column>
                      <Form.Field>
                        <label>Track Offset</label>
                        <Input name="trackOffset" type="number" placeholder="10" action onChange={this.onChange} />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={16}>
                      <Divider />
                      <Header>The Locations {distance && <span style={{ color: "white" }}>{distance.toFixed(2)} km apart</span>}</Header>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={"equal"}>
                    <Grid.Column>{this.renderMissingFrom()}</Grid.Column>
                    <Grid.Column>{this.renderFindLocation()}</Grid.Column>
                  </Grid.Row>
                  <Divider />
                  <Grid.Row columns={"equal"}>
                    <Grid.Column>
                      <Button
                        negative
                        onClick={e => {
                          this.clearForm(e);
                        }}
                      >
                        clear
                      </Button>
                    </Grid.Column>
                    <Grid.Column>
                      <Button positive fluid onClick={() => this.props.submit(this.state.data)}>
                        add
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            </Grid.Column>
            <Grid.Column>
              <TheMap ipp={this.addipp} find={this.addFind} ippLocation={this.state.data.ippLocation} findLocation={this.state.data.findLocation} click={true} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
