import React, {Component}      from "react";
import {Route, Switch}         from "react-router-dom";
import Navbar                  from "./components/common/Navbar";
import FooterNav               from "./components/common/footerNav";
import UnpublishedSearchList   from "./components/stat/unpublishedSearches";
import PublishedSearchList     from "./components/stat/publishedSearches";
import UncheckedSearchList     from "./components/stat/uncheckedSearches";
import Home                    from "../src/components/home";
import AddStat                 from "./components/stat/addStat";
import Settings                from "./components/settings";
import Map                     from "./components/stat/globalMap";
import SearchList              from "./components/stat/allSearches";
import ShowSearch              from "./components/stat/showSearch";
import AppConfig               from "./_appConfig";
import {AuthenticationWrapper} from "@liquidcomputing/sarstuff__reactjs-singlesignon";
import "semantic-ui-css/semantic.min.css";

class App extends Component {
  render() {
    return (
      <AuthenticationWrapper
        config={{
          clientId: AppConfig.auth.clientId,
          loginServer: AppConfig.auth.loginServer,
          applicationRoot: AppConfig.auth.applicationRoot,
          localPrefix: AppConfig.app_storage_prefix,
          applicationServer: AppConfig.api_server
        }}
      >
        <div className="ui container fluid  wrapper">
          <Navbar match={this.props.match} history={this.props.history} />
          <Switch>
            <Route exact path="/map" component={Map} />
            <Route exact path="/stat/add" component={AddStat} />
            <Route path="/settings" component={Settings} />
            <Route exact path="/" component={Home} />
            <Route exact path="/searches" component={SearchList} />
            <Route exact path="/searches/unpublished" component={UnpublishedSearchList} />
            <Route exact path="/searches/unchecked" component={UncheckedSearchList} />
            <Route exact path="/searches/published" component={PublishedSearchList} />
            <Route exact path="/searches/:id" component={ShowSearch} />
          </Switch>
          <FooterNav match={this.props.match} history={this.props.history} />
        </div>
      </AuthenticationWrapper>
    );
  }
}

export default App;
