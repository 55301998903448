const initialState = {
  settings: {},
  loading: false,
  error: null
};
export function system(state = initialState, action = {}) {
  switch (action.type) {
    case "SYSTEM_SETTINGS_BEGIN":
      return {
        ...state,
        loading: true,
        error: null
      };
    case "SYSTEM_SETTINGS_GOT":
      return {
        ...state,
        loading: false,
        settings: action.payload
      };
    case "SYSTEM_SETTINGS_FALIURE":
      return {
        ...state,
        loading: false,
        error: action.payload,
        settings: {}
      };
    case "SYSTEM_SETTINGS_UPDATING":
      return {
        ...state,
        loading: false,
        settings: action.payload
      };

    default:
      return state;
  }
}
const defaultsState = {
  logDefault: {},
  logDefaults: [],
  loading: false,
  error: null
};
export function defaults(state = defaultsState, action = {}) {
  switch (action.type) {
    case "DEFAULTS_BEGIN":
      return {
        ...state,
        loading: true,
        error: null
      };
    case "LOG_DEFAULTS_FALIURE":
      return {
        ...state,
        loading: false,
        error: action.payload,
        incident: {}
      };
    case "LOG_DEFAULTS_GOT":
      return {
        ...state,
        loading: false,
        error: null,
        logDefaults: action.payload
      };
    case "LOG_DEFAULT_GOT":
      return {
        ...state,
        loading: false,
        error: null,
        logDefault: action.payload
      };
    case "LOG_DEFAULT_UPDATED":
      return {
        ...state,
        loading: false,
        error: null,
        logDefault: action.payload
      };
    case "LOG_DEFAULT_ADDED":
      return {
        ...state,
        loading: false,
        error: null,
        logDefault: action.payload
      };
    case "LOG_DEFAULT_REMOVED":
      return {
        ...state,
        loading: false,
        error: null,
        logDefault: {}
      };
    default:
      return state;
  }
}
