import React from "react";
import { Link } from "react-router-dom";
import { Menu, Icon, Image } from "semantic-ui-react";

class Navbar extends React.Component {
  state = {};
  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };
  handleLogout = () => {
    // this.props.logout();
  };
  handleClear = () => {
    this.props.clear(() => this.props.history.push("/"));
  };

  basicMenu() {
    const { activeItem } = this.state;
    return (
      <React.Fragment>
        <Menu.Item name={"searches"} as={Link} to="/searches/published" active={activeItem === "searches"} onClick={this.handleItemClick}>
          Published
        </Menu.Item>
        <Menu.Item name={"unchecked-searches"} as={Link} to="/searches/unchecked" active={activeItem === "unchecked-searches"} onClick={this.handleItemClick}>
          Unchecked
        </Menu.Item>
        <Menu.Item name={"unpublished-searches"} as={Link} to="/searches/unpublished" active={activeItem === "unpublished-searches"} onClick={this.handleItemClick}>
          Unpublished
        </Menu.Item>
        <Menu.Item name={"stat"} as={Link} to="/stat/add" active={activeItem === "stat"} onClick={this.handleItemClick}>
          Add Stat
        </Menu.Item>
        <Menu.Item name={"map"} as={Link} to="/map" active={activeItem === "map"} onClick={this.handleItemClick}>
          <Icon name="globe" /> map
        </Menu.Item>
      </React.Fragment>
    );
  }
  render() {
    const { activeItem } = this.state;

    return (
      <Menu stackable className={"main-menu"}>
        <Menu.Item style={{ width: 110, padding: 4 }}>
          <Image className="logo" src={require("../../assets/logo.png")} alt="SARSYS" />
        </Menu.Item>
        <Menu.Item name={"home"} as={Link} to="/" active={activeItem === "home"} onClick={this.handleItemClick}>
          <Icon name="home" />
        </Menu.Item>

        {this.basicMenu()}
      </Menu>
    );
  }
}

export default Navbar;
