import React from "react";

import { Segment, Header } from "semantic-ui-react";
import api from "../../actions/api";
import SearchList from "../common/lists/searchList";
export default class AllPublishedSearches extends React.Component {
  state = {
    searches: []
  };

  componentDidMount = () => {
    return api.search.published().then(res => {
      this.setState({
        searches: res
      });
    });
  };

  render() {
    return (
      <Segment basic>
        <Header>Published ({this.state.searches.length})</Header>
        <SearchList data={this.state.searches} />
      </Segment>
    );
  }
}
