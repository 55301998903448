import React, { Component } from "react";
import { Segment, Header, Grid, Select, Button } from "semantic-ui-react";
import * as turf from "@turf/turf";
import api from "../../actions/api";
import ReactMapboxGl, { ZoomControl, ScaleControl, GeoJSONLayer } from "react-mapbox-gl";
// import { RegionDropdown } from "react-country-region-selector";
const { token, styles } = require("../common/basicMap/config.json");
const Map = ReactMapboxGl({
  accessToken: token,
  preserveDrawingBuffer: true
});

const circleLayout = { visibility: "visible" };
const circlePaint = {
  "circle-stroke-width": 3,
  "circle-radius": 4,
  "circle-blur": 0.15,
  "circle-stroke-color": "#eaa29b",
  "circle-color": "red"
};

export default class GlobalMap extends Component {
  state = {
    styleKey: "outdoor",
    marker: [],
    searches: [],
    region: "",
    counties: [],
    filtered: []
  };
  componentDidMount = () => {
    this.getData();
  };
  getData = () => {
    api.search.published().then(res => {
      this.setState(
        {
          searches: res,
          filtered: res
        },
        () => {
          this.mapBounds();
          this.mapCounties();
        }
      );
    });
  };

  mapCounties = () => {
    if (this.state.searches) {
      let counties = this.state.searches.map(search => {
        return search.county;
      });
      let finalCounties = [];
      for (let i in counties) {
        let county = counties[i];
        if (finalCounties.indexOf(county) === -1) {
          finalCounties.push(county);
        }
      }
      this.setState({
        counties: finalCounties
          .sort((a, b) => {
            if (a < b) {
              return -1;
            } else if (a > b) {
              return 1;
            }
            return 0;
          })
          .map((item, i) => {
            return {
              key: i,
              text: item,
              value: item
            };
          })
      });
    }
  };
  mapBounds = () => {
    let data = [];
    if (this.map) {
      if (this.state.filtered && this.state.filtered.length) {
        this.state.filtered.map((loc, i) => {
          if (loc.ippLocation) {
            if (loc.ippLocation.geometry) {
              data.push(loc.ippLocation.geometry.coordinates);
            }
          }
          return null;
        });
        let list = turf.multiPoint(data);
        let bbox = turf.bbox(list);
        this.map.state.map.fitBounds(bbox, { padding: 180 });
      }
    }
    return null;
  };
  renderData = data => {
    let locations = data.map(loc => {
      return loc.ippLocation;
    });
    return {
      type: "FeatureCollection",
      features: locations
    };
  };
  renderGeoJSON() {
    let bob = this.renderData(this.state.filtered);
    return <GeoJSONLayer id="locations" data={bob} circleLayout={circleLayout} circlePaint={circlePaint} />;
  }

  handleDropdownChange = (props, e) => {
    this.setState(
      {
        region: e.value
      },
      () => {
        if (this.state.region) {
          let orgs = this.state.searches.filter(item => {
            return item.county === this.state.region;
          });
          this.setState({ filtered: orgs }, () => {
            this.mapBounds();
          });
        } else {
          this.setState({ filtered: this.state.searches }, () => {
            this.mapBounds();
          });
        }
      }
    );
  };
  reset = () => {
    this.setState(
      {
        region: ""
      },
      () => {
        this.setState({ filtered: this.state.searches }, () => {
          this.mapBounds();
        });
      }
    );
  };
  render() {
    const { styleKey, region } = this.state;
    return (
      <Segment style={{ padding: 0, width: 100 + "%", height: 85 + "vh", margin: 0 }}>
        <Segment basic style={{ margin: 0 }}>
          <Grid verticalAlign="middle">
            <Grid.Row>
              <Grid.Column width={4}>
                <Select placeholder={"pick one"} name={"county"} onChange={this.handleDropdownChange} options={this.state.counties} value={region} />
                <Button
                  onClick={() => {
                    this.reset();
                  }}
                >
                  clear
                </Button>
                {/* <RegionDropdown country={"United Kingdom"} value={region} onChange={val => this.selectRegion(val)} classes="ui selection dropdown" /> */}
              </Grid.Column>
              <Grid.Column width={12}>
                <Header>Searches: {this.state.filtered.length}</Header>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Map
          ref={e => {
            this.map = e;
          }}
          onClick={this.props.click ? this._onClickMap : () => console.log("clicked")}
          //   center={center}
          className="basicMap"
          style={styles[styleKey]}
          onStyleLoad={this.onStyleLoad}
        >
          {this.state.searches.length && this.renderGeoJSON()}
          <ZoomControl />
          <ScaleControl position={"bottom-left"} />
        </Map>
      </Segment>
    );
  }
}
