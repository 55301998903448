import api from "./api";

export const getTypeBehaviours = id => dispatch => {
  api.settings.getTypeBehaviours(id).then(typeBehaviours => {
    dispatch({
      type: "TYPE_BEHAVIOURS_GOT",
      typeBehaviours
    });
  });
};

export const getMisperTypes = () => dispatch => {
  return api.stats.getMisperTypes().then(res => {
    return res;
  });
};
export const getLocationTypes = () => dispatch => {
  return api.stats.getLocationTypes().then(res => {
    return res;
  });
};
export const getMissingFrom = () => dispatch => {
  return api.stats.getMissingFrom().then(res => {
    return res;
  });
};
export const getAreaType = () => dispatch => {
  return api.stats.getAreaType().then(res => {
    return res;
  });
};

export const getSystemSettings = () => dispatch => {
  return api.settings.getSystemSettings().then(system => {
    dispatch({
      type: "SYSTEM_SETTINGS_GOT",
      payload: system
    });
  });
};

export const settingsBegin = () => ({
  type: "SYSTEM_SETTINGS_BEGIN"
});
export const updateSetting = (data, cb) => dispatch => {
  api.settings.updateSetting(data).then(system => {
    dispatch({
      type: "SYSTEM_SETTINGS_UPDATING",
      payload: system
    });
    dispatch(getSystemSettings());
    cb();
  });
};
