import React from "react";

import { Route, Switch } from "react-router-dom";
import { Container, Segment } from "semantic-ui-react";
import SettingsNav from "./settingsNav";
import SettingsList from "./theSettingsList";

import Dash from "./dash";
export class SettingsPage extends React.Component {
  componentWillMount() {}

  render() {
    return (
      <Container fluid style={{ padding: 0 }}>
        <SettingsNav match={this.props.match} />
        <Segment basic>
          <Switch>
            <Route exact path={`${this.props.match.url}`} component={Dash} />
            <Route exact path={`${this.props.match.url}/lists`} component={SettingsList} />
          </Switch>
        </Segment>
      </Container>
    );
  }
}

export default SettingsPage;
