import axios        from "axios";
import AppConfig    from "../_appConfig";
import {APIRequest} from "@liquidcomputing/sarstuff__reactjs-singlesignon";
const server = AppConfig.api_server;

export default {
  stats: {
      getMisperTypes: () => axios.get(server + `/public/mispertypes`).then(res => res.data),
      getLocationTypes: () => axios.get(server + `/public/findlocations`).then(res => res.data),
      getMissingFrom: () => axios.get(server + `/public/missingfrom`).then(res => res.data),
      getAreaType: () => axios.get(server + `/public/areatypes`).then(res => res.data)
  },
  settings: {
    getTypeBehaviours: () => APIRequest.get(server + `/settings/typebehaviour`).then(res => res.data),
    getTypeBehaviour: type => APIRequest.get(server + `/settings/typebehaviour/${type}`).then(res => res.data),
    addTypeBehaviour: typeBehaviour => APIRequest.post(server + `/settings/typebehaviour`, typeBehaviour).then(res => res.data),
    getSystemSettings: () => APIRequest.get(server + `/settings/system`).then(res => res.data),
    updateTypeBehaviour: (id, typeBehaviour) => APIRequest.patch(server + `/settings/typebehaviour/${id}`, typeBehaviour).then(res => res.data),
    updateSetting: setting => APIRequest.patch(server + `/settings/system/`, setting).then(res => res.data),
    removeTypeBehaviour: id => APIRequest.delete(server + `/settings/typebehaviour/${id}`).then(res => res.data)
  },
  search: {
    add: data => APIRequest.post(server + `/search`, data).then(res => res.data),
    all: () => APIRequest.get(server + `/search`).then(res => res.data),
    show: id => APIRequest.get(server + `/search/${id}`).then(res => res.data),
    update: (id, data) => APIRequest.patch(server + `/search/update/${id}`, data).then(res => res.data),
    check: (id, data) => APIRequest.patch(server + `/search/${id}`, data).then(res => res.data),
    dash: () => APIRequest.get(server + `/search/dash`).then(res => res.data),
    notPublished: () => APIRequest.get(server + `/search/unpublished`).then(res => res.data),
    unchecked: () => APIRequest.get(server + `/search/unchecked`).then(res => res.data),
    published: () => APIRequest.get(server + `/search/published`).then(res => res.data),
    ignored: () => APIRequest.get(server + `/search/ignored`).then(res => res.data)
  }
};