import React, { Component } from "react";
import { connect } from "react-redux";
import { Header, Segment } from "semantic-ui-react";
import AddStatForm from "./forms/addStatFrom";
import * as turf from "@turf/turf";
import { getSystemSettings } from "../../actions/settingsActions";
import Moment from "react-moment";
import api from "../../actions/api";
function mapStateToProps(state) {
  return {
    system: state.system
  };
}
// Moment.globalTimezone = "Europe/London";
class AddStat extends Component {
  state = {
    data: {
      type: "",
      age: "",
      gender: "",
      missingFromType: "",
      ippLocation: {},
      findLocation: {}
    }
  };
  componentDidMount = () => {
    this.props.getSystemSettings();
  };

  onSubmit = data => {
    return api.search.add(data).then(res => {
      this.props.history.push(`/searches/${res._id}`);
    });
  };
  onChange = data => {
    if (this.state.data !== data) {
      this.setState({
        ...this.state.data,
        data: data
      });
    }
  };
  renderTheStory() {
    let from, to, distance;
    if (this.state.data.ippLocation.geometry) {
      from = turf.point(this.state.data.ippLocation.geometry.coordinates);
    }
    if (this.state.data.findLocation.geometry) {
      to = turf.point(this.state.data.findLocation.geometry.coordinates);
    }
    if (from && to) {
      let options = { units: "kilometers" };
      distance = turf.distance(from, to, options);
    }

    return (
      <Segment basic>
        <Header className="theStory">
          The misper type is <span>{this.state.data.misperType}</span>
          and they are a <span>{this.state.data.gender}</span>, their age is <span>{this.state.data.age}</span>. They went missing from a{" "}
          <span>{this.state.data.missingFromType} </span>
          in a <span>{this.state.data.areaType} </span> area. They were found in/on a <span>{this.state.data.locationType}</span>, <span>{this.state.data.misperCondition}</span>{" "}
          <br />
          {distance && (
            <React.Fragment>
              They had traveled <span style={{ color: "white" }}>{distance.toFixed(2)} km </span> in approx{" "}
              {this.state.data.dateFound && this.state.data.dateMissing && (
                <React.Fragment>
                  {" "}
                  <span>
                    <Moment diff={this.state.data.dateMissing} unit="hours">
                      {this.state.data.dateFound}
                    </Moment>{" "}
                    hours{" "}
                  </span>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          {this.state.data.dateMissing && (
            <React.Fragment>
              They went missing{" "}
              <span>
                @ <Moment locale="en" format="DD MM YYYY HH:mm" date={this.state.data.dateMissing} />{" "}
              </span>
            </React.Fragment>
          )}
          {this.state.data.dateFound && (
            <React.Fragment>
              and were found{" "}
              <span>
                @ <Moment locale="en" format="DD MM YYYY HH:mm" date={this.state.data.dateFound} />
              </span>
            </React.Fragment>
          )}
          {this.state.data.trackOffset && (
            <React.Fragment>
              {" "}
              They were
              <span> {this.state.data.trackOffset} meters off a track or path</span>
            </React.Fragment>
          )}
        </Header>
      </Segment>
    );
  }
  render(){
    return (
      <React.Fragment>
        <Segment attached="top">{this.renderTheStory()}</Segment>
        <Segment>
          <AddStatForm submit={this.onSubmit} data={this.onChange} settings={this.props.system.settings} />
        </Segment>
      </React.Fragment>
    );
  }
}
export default connect(
  mapStateToProps,
  { getSystemSettings }
)(AddStat);
