import React from "react";
import { connect } from "react-redux";
import { Grid, Segment, Tab } from "semantic-ui-react";
import { getSystemSettings, updateSetting, getMisperTypes } from "../../actions/settingsActions";
import SettingsList from "../common/lists/settingList";

function mapStateToProps(state) {
  return {
    system: state.system
  };
}

class SettingsListPage extends React.Component {
  state = {
    disabled: true,
    data: {
      teamName: "",
      systemName: ""
    },
    missingFromTypes: [],
    misperTypes: [],
    findLocationTypes: []
  };
  updateFormTimeout = 0;
  componentDidMount() {
    this.props.getSystemSettings();
  }
  componentDidUpdate = (prevProps, prevState) => {};
  toggleDisabled = () => {
    this.setState({
      disabled: !this.state.disabled
    });
  };
  onChange = e =>
    this.setState(
      {
        data: { ...this.state.data, [e.target.name]: e.target.value }
      },
      () => {
        this.props.updateSetting(this.state.data, () => {});
      }
    );
  updateModel = e => {
    this.setState({
      updating: true,
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
    if (this.updateFormTimeout) {
      clearTimeout(this.updateFormTimeout);
    }
    this.updateFormTimeout = setTimeout(() => {
      return this.props
        .updateSetting(this.state.data)
        .then(res => {
          this.setState({
            updating: false
          });
          //TODO add a fancy screen notification that the title has successfully updated
        })
        .catch(err => {
          console.error("Unable to save", err);
        });
    }, 500);
  };

  handleSubmit = data => {
    return this.props.updateSetting(data, () => {});
  };
  handleDelete = data => {
    this.props.updateSetting(data, () => {});
  };
  getDataTypes = data => {
    if (data) {
      // const data = this.state.types;
      let dataArray = [];
      for (var key in data) {
        dataArray.push({
          key: data[key].ID,
          text: data[key].Title,
          value: data[key].Title
        });
      }
      return dataArray;
      // this.setState({ name: dataArray });
    }
  };
  renderKSTATLists() {
    return (
      <Grid stackable className={"no-border"}>
        <Grid.Row>
          <Grid.Column width={4}>
            <Segment>
              <SettingsList
                submit={this.handleSubmit}
                delete={this.handleDelete}
                data={this.props.system.settings.misperTypes}
                placeholder="misperTypes."
                title="Misper Types"
                field="misperTypes"
              />
            </Segment>
          </Grid.Column>
          <Grid.Column width={12}>
            <Grid stackable className={"no-border"}>
              <Grid.Row columns={3}>
                <Grid.Column>
                  <Segment>
                    <SettingsList
                      submit={this.handleSubmit}
                      delete={this.handleDelete}
                      data={this.props.system.settings.missingFrom}
                      placeholder="missingFrom types"
                      title="Missing From Locations"
                      field="missingFrom"
                    />
                  </Segment>
                </Grid.Column>
                <Grid.Column>
                  <Segment>
                    <SettingsList
                      submit={this.handleSubmit}
                      delete={this.handleDelete}
                      data={this.props.system.settings.findLocationTypes}
                      placeholder="Find Location Types."
                      title="Find Location Types"
                      field="findLocationTypes"
                    />
                  </Segment>
                </Grid.Column>
                <Grid.Column>
                  <Segment>
                    <SettingsList
                      submit={this.handleSubmit}
                      delete={this.handleDelete}
                      data={this.props.system.settings.areaType}
                      placeholder="area type"
                      title="Area Type"
                      field="areaType"
                    />
                  </Segment>
                </Grid.Column>
                <Grid.Column>
                  <Segment>
                    <SettingsList
                      submit={this.handleSubmit}
                      delete={this.handleDelete}
                      data={this.props.system.settings.outcome}
                      placeholder="Outcome type"
                      title="Outcome Type"
                      field="outcome"
                    />
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  render() {
    const panes = [
      { menuItem: "KSTAT Lists", render: () => <Tab.Pane>{this.renderKSTATLists()}</Tab.Pane> }
      // { menuItem: "Task Lists", render: () => <Tab.Pane>{this.renderTaskSettings()}</Tab.Pane> },
      // { menuItem: "Contact Settings", render: () => <Tab.Pane>{this.renderContactSettings()}</Tab.Pane> }
    ];
    return (
      // <Segment basic>
      <Tab menu={{ secondary: true, pointing: true, attached: false }} panes={panes} />
      // </Segment>
    );
  }
}

export default connect(
  mapStateToProps,
  { getSystemSettings, updateSetting, getMisperTypes }
)(SettingsListPage);
